import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddSubscriptionModel } from 'src/app/models/add-subscriptionModel';
import { ConfirmSubscriptionModel } from 'src/app/models/confirm-subscriptionModel';
import { SeachHelper } from 'src/app/models/searchHelper';
import { SubscribeStripeModel } from 'src/app/models/subscribe-stripeModel';
import { AlertService } from '../alert/alert.service';
import { environment, API_URL } from 'src/environments/environment';
import { PlaylistSong, Song, TrainingSessionSong } from 'src/app/models/songModel';
import { Playlist } from 'src/app/models/playlist';
import { StripeIntent, StripePaymentMethod } from 'src/app/models/stripeIntent';
import { DisciplineModel } from 'src/app/models/disciplineModel';
import { TrainingSession } from 'src/app/models/trainingSession';
import { Announce } from 'src/app/models/announce';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  // BASE_URL: "https://apimusic.wide-company.com/api/"
  ///////////base_url = "https://apimusic.wide-company.com/api/v1/";
  base_url = API_URL + "v1/";
  // base_url1 = "http://widemusic.localhost/api/v1/";
  // private readonly base_url = environment.BASE_URL;

  constructor(
    private http: HttpClient,
    private alert: AlertService
  ) {
  }

  getPaymentMethods() {
    return this.http.get(`${this.base_url}paymethods`);
  }

  getProfile() {
    return this.http.get(`${this.base_url}profile`);
  }

  updateProfile(data){
    return this.http.post(`${this.base_url}updateProfile`, data);
  }

  getPlans() {
    return this.http.get(`${this.base_url}plans`);
  }

  getSongs(data: SeachHelper) {
    return this.http.post(`${this.base_url}songs`, data);
  }

  async getStripeIntent(): Promise<StripeIntent> {
    const response = await this.http.post<StripeIntent>(`${this.base_url}stripe/intent`, {}).toPromise();
    return response;
  }

  async getCurrentPaymentMethod(): Promise<StripePaymentMethod> {
    const response = await this.http.get<StripePaymentMethod>(`${this.base_url}stripe/currentPaymentMethod`, {}).toPromise();
    return response;
  }

  async getRandomTrack(data: SeachHelper): Promise<Song> {
    const response = await this.http.post<Song>(`${this.base_url}randomTrack`, data).toPromise();
    return response;
  }

  async getRandomTrainingTrack(dance_id: number, track_duration: number): Promise<TrainingSessionSong> {
    const response = await this.http.post<TrainingSessionSong>(`${this.base_url}trainingsession/randomtrack`, {dance_id: dance_id, min_duration: track_duration}).toPromise();
    return response;
  }

  async getAnnounces(): Promise<Announce[]> {
    const response = await this.http.get<Announce[]>(`${this.base_url}announces`).toPromise();
    return response;
  }

  // callApi(endpoint: string, data: any, method: "post"){
  //   return this.http.request(method,`${this.base_url}${endpoint}`,{body: data}).subscribe((data)=>{
  //     return data;
  //   },
  //   (error)=>{
  //     if(error.error.message == "Unauthenticated."){
  //       localStorage.clear();
  //       this.alert.showAlert("La sessione è scaduta, oppure hai effettuato l'accesso con un altro dispositivo.",true);
  //     }
  //   }
  //   );
  // }

  getNextSongs(url: string,data: SeachHelper) {
    return this.http.post(url, data);
  }

  addSongCount(id: number){
    return this.http.put(`${this.base_url}song/count`, { id: id });
  }

  getDances() {
    return this.http.get(`${this.base_url}dances`);
  }

  getTags(danceId:  number) {
    return this.http.get(`${this.base_url}tags?dance_id=${danceId}`);
  }

  getDisciplines() {
    return this.http.get(`${this.base_url}disciplines`);
  }

  subscribe(data: AddSubscriptionModel){
    return this.http.post(`${this.base_url}subscribe`, data);
  }

  updateSubscription(data: AddSubscriptionModel){
    return this.http.post(`${this.base_url}updateSubscription`, data);
  }

  deleteSubscription(data: AddSubscriptionModel){
    return this.http.post(`${this.base_url}unsubscribe`, data);
  }

  getSubscription(){
    return this.http.get(`${this.base_url}subscription`)
  }

  subscribeStripe(data: SubscribeStripeModel){
    return this.http.post(`${this.base_url}subscribeStripe`, data);
  }

  updatePaymentMethod(data: SubscribeStripeModel){
    return this.http.post(`${this.base_url}stripe/updatePaymentMethod`, data);
  }

  confirmSubscription(data: ConfirmSubscriptionModel){
    return this.http.post(`${this.base_url}confirmSubscription`, data);
  }

  async getAllDisciplines(): Promise<DisciplineModel[]> {
    const response = await this.http.get<DisciplineModel[]>(`${this.base_url}disciplines`, {}).toPromise();
    return response;
  }

  async saveTrainingSession(trainingSession: TrainingSession): Promise<TrainingSession> {
    const response = await this.http.post<TrainingSession>(`${this.base_url}trainingsession`, {trainingSession}).toPromise();
    return response;
  }

  async getTrainingSessionPlaylist(trainingSession: TrainingSession): Promise<TrainingSessionSong[]> {
    let querystring = '';
    if (trainingSession.announces_enabled === true) {
      querystring = '?withAnnounces=1';
    }
    const response = await this.http.get<TrainingSessionSong[]>(`${this.base_url}trainingsession/` + trainingSession.id + '/playlist' + querystring).toPromise();
    return response;
  }

  // Playlist

  async getPlaylists(): Promise<Playlist[]> {
    const response = await this.http.get<Playlist[]>(`${this.base_url}playlist/getPlaylists`, {}).toPromise();
    return response;
  }

  async getPlaylist(playlistId: number): Promise<Playlist> {
    const response = await this.http.get<Playlist>(`${this.base_url}playlist/getPlaylist?id=` + playlistId).toPromise();
    return response;
  }

  async savePlaylist(newPlaylist: Playlist) {
    const response = await this.http.put<Playlist>(`${this.base_url}playlist/savePlaylist`, newPlaylist).toPromise();
    return response;
  }

  async deletePlaylist(playlist: Playlist) {
    const response = await this.http.delete<Playlist>(`${this.base_url}playlist/deletePlaylist`, {body: playlist}).toPromise();
    return response;
  }

  async addSongToPlaylist(song: Song, playlist: Playlist) {
    const response = await this.http.put<Playlist>(`${this.base_url}playlist/addSongToPlaylist`, {song_id: song.id, playlist_id: playlist.id}).toPromise();
    return response;
  }

  async removeSongFromPlaylist(playlistSong: PlaylistSong) {
    const response = await this.http.delete<Playlist>(`${this.base_url}playlist/removeSongFromPlaylist`, {body: playlistSong}).toPromise();
    return response;
  }

  async deleteMyAccount() {
    const response = await this.http.delete(`${this.base_url}myaccount`).toPromise();
    return response;
  }
}
