import { Injectable } from "@angular/core";
import { Device } from "@capacitor/device";
import { Platform } from "@ionic/angular";
import { IosExternalLink } from "plugins_src/ios-external-link/src";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
export class IosService {

    isAppleApp = false;
    isIOS16 = false;
    isIpad = false;

    constructor(
        public platform: Platform
    ) {
        this.checkIfApp();
     }

    async checkIfApp() {
        const platform = this.platform.platforms();
        if ((platform.includes('iphone') || platform.includes('ios') || platform.includes('ipad')) && !platform.includes('mobileweb')) {
          if (platform.includes('ipad')) {
            this.isIpad = true;
          }
          this.isAppleApp = true;
          const info = await Device.getInfo();
          if(Number(info.osVersion.split('.')[0]) >= 16) {
            console.log('iOS 16+');
            this.isIOS16 = true;
          }
        }
      }
    

      async canMakePayments() {
        return (await IosExternalLink.canMakePayments()).result;
      }

      async openExternalLinkIOS16() {
        const canOpen = true; //(await IosExternalLink.canOpen()).result;
        if (canOpen) {
            await IosExternalLink.open();
        }
      }
}