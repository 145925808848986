import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './services/auth/authguard.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    loadChildren: () => import('./pages/loader/loader.module').then( m => m.LoaderPageModule)
  },
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/auth/register/register.module').then( m => m.RegisterPageModule)
      },
      {
        path: 'forget-password',
        loadChildren: () => import('./pages/auth/forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule)
      },
      {
        path: 'create-password',
        loadChildren: () => import('./pages/auth/create-password/create-password.module').then( m => m.CreatePasswordPageModule)
      },
      {
        path: 'changed-password',
        loadChildren: () => import('./pages/auth/changed-password/changed-password.module').then( m => m.ChangedPasswordPageModule)
      },
      {
        path: 'insert-code',
        loadChildren: () => import('./pages/auth/insert-code/insert-code.module').then( m => m.InsertCodePageModule)
      },
    ]
  },
  {
    path: 'subscription',
    // canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/subscription/subscription.module').then( m => m.SubscriptionPageModule)
  },
  {
    path: 'payment-method',
    // canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/payment-method/payment-method.module').then( m => m.PaymentMethodPageModule)
  },
  {
    path: 'home',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/homepage/homepage.module').then( m => m.HomepagePageModule)
  },
  {
    path: 'home/pid/:id',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/homepage/homepage.module').then( m => m.HomepagePageModule)
  },
  {
    path: 'profile',
    canActivate: [AuthguardGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/profile/profile/profile.module').then( m => m.ProfilePageModule)
      },
      {
        path: 'edit-profile',
        loadChildren: () => import('./pages/profile/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
      },
      {
        path: 'edit-subscription',
        loadChildren: () => import('./pages/profile/edit-subscription/edit-subscription.module').then( m => m.EditSubscriptionPageModule)
      },
      {
        path: 'edit-payment-method',
        loadChildren: () => import('./pages/profile/edit-payment-method/edit-payment-method.module').then( m => m.EditPaymentMethodPageModule)
      },
      {
        path: 'edit-playlist',
        loadChildren: () => import('./pages/profile/edit-playlist/edit-playlist.module').then( m => m.EditPlaylistPageModule),
      },
      {
        path: 'training-session',
        loadChildren: () => import('./pages/profile/training-session/training-session.module').then( m => m.TrainingSessionPageModule),
      }
    ]
  },
  { path: '**', redirectTo: 'home' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
