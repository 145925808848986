import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-terms-and-conditions',
  templateUrl: './m-terms-and-conditions.component.html',
  styleUrls: ['./m-terms-and-conditions.component.scss'],
})
export class MTermsAndConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  openTerms(){

  }
}
