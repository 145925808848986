import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-m-input-icon',
  templateUrl: './m-input-icon.component.html',
  styleUrls: ['./m-input-icon.component.scss'],
})
export class MInputIconComponent implements OnInit {
  @Input() label: string;
  @Input() type: string;
  @Input() icon: string;
  @Input() value: string;
  @Output() returnValue = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {}


  onChange(event: any){
    this.returnValue.emit(event.detail.value);
  }
}
