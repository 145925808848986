import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-button-dance',
  templateUrl: './m-button-dance.component.html',
  styleUrls: ['./m-button-dance.component.scss'],
})
export class MButtonDanceComponent implements OnInit {
  @Input() text: string;
  @Input() isSelected: boolean;
  constructor() { }

  ngOnInit() {}

}
