import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-button-outline',
  templateUrl: './m-button-outline.component.html',
  styleUrls: ['./m-button-outline.component.scss'],
})
export class MButtonOutlineComponent implements OnInit {
  @Input() text: string;
  
  constructor() { }

  ngOnInit() {}

}
