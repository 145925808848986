import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Playlist } from 'src/app/models/playlist';
import { SeachHelper } from 'src/app/models/searchHelper';
import { Song, SongModel } from 'src/app/models/songModel';
import { StreamState } from 'src/app/models/streamState';
import { HttpService } from 'src/app/services/http/http.service';
import { PlayerService } from 'src/app/services/player/player.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-player-modal',
  templateUrl: './player-modal.component.html',
  styleUrls: ['./player-modal.component.scss'],
})
export class PlayerModalComponent implements OnInit {
  @Input() player: PlayerService;
  @Input() song: Song;
  state: StreamState;
  public static searchHelper: SeachHelper = {};
  progressValue: number;
  playbackValue: number = 1;
  isRepeatModeEnabled = false;
  isShuffleModeEnabled = false;
  @Input() songList: Song[];
  actionSelected = null;
  newPlaylist: Playlist = new Playlist();
  playlists: Playlist[] = [];
  isModalOpen = false;


  constructor(
    private modalController: ModalController,
    private storage: StorageService,
    private http: HttpService,
    private alertController: AlertController
  ) {
    setTimeout(() => {
      this.player.getState()
      .subscribe(state => {
        this.state = state;
        this.progressValue = (this.state.currentTime / this.state.duration);
        if(this.state.playbackRate){
          this.playbackValue = this.state.playbackRate;
        }
      });
      this.playbackValue = localStorage.getItem('playbackRate') ? parseFloat(localStorage.getItem('playbackRate')) : 1;
    }, 0);
  }

  ngOnInit() {
    const isShuffleModeEnabled = localStorage.getItem('shuffleMode');
    if (isShuffleModeEnabled && isShuffleModeEnabled === 'true') {
      this.isShuffleModeEnabled = true;
    }

    const isRepeatModeEnabled = localStorage.getItem('repeatMode');
    if (isRepeatModeEnabled && isRepeatModeEnabled === 'true') {
      this.isRepeatModeEnabled = true;
    }
    
    this.player.songList.subscribe((data)=>{
      this.songList = data;
    });

    this.loadPlaylists();
   }

  close() {
    this.modalController.dismiss({
      selectedSong: this.player.song,
      playback: this.playbackValue
    });
  }

  play() {
    this.player.play();
  }
  pause() {
    this.player.pause();
  }
  async next() {
    const playback = this.playbackValue;
    const isShuffleModeEnabled = localStorage.getItem('shuffleMode');
    if (isShuffleModeEnabled && isShuffleModeEnabled === 'true') {
      const song = await this.getRandomTrack();
      this.openSong(song);
      this.openFile(song);
      this.player.playbackRate(playback);
      this.play();
    } else {
      const index = this.songList.lastIndexOf(this.player.song) + 1;
      if (index == this.songList.length - 2) {
        this.player.loadNextPage.next(true);
      }
      const file = this.songList[index];
      this.player.song = file;
      this.openFile(file);
      this.player.playbackRate(playback);
      this.player.play();
    }
  }
  async previous() {
    const playback = this.playbackValue;
    const isShuffleModeEnabled = localStorage.getItem('shuffleMode');
    if (isShuffleModeEnabled && isShuffleModeEnabled === 'true') {
      const song = await this.getRandomTrack();
      this.openSong(song);
      this.openFile(song);
      this.player.playbackRate(playback);
      this.play();
    } else {
      const index = this.songList.lastIndexOf(this.player.song) - 1;
      const file = this.songList[index];
      this.player.song = file;
      this.openFile(file);
      this.player.playbackRate(playback);
      this.player.play();
    }
    
  }

  getRandomTrack() {
    PlayerModalComponent.searchHelper.disciplines = [this.songList[0].discipline.id];
    PlayerModalComponent.searchHelper.dances = [this.songList[0].dance.id];
    let tags = null;
    try {
      tags = this.storage.getItem('tags');
    } catch (e) {
      tags = null;
    }
    if (tags && tags !== undefined && tags.length > 0) {
      PlayerModalComponent.searchHelper.tags = tags;
    }
    if (this.songList[0].playlist_id) {
      PlayerModalComponent.searchHelper.playlist = this.songList[0].playlist_id;
    }
    if (this.player.song) {
      PlayerModalComponent.searchHelper.currentSong = this.player.song.id;
    }
    return this.http.getRandomTrack(PlayerModalComponent.searchHelper);
  }

  isFirstPlaying() {
    if(this.player.song){
      return this.songList.lastIndexOf(this.player.song) === 0;
    }
    return false;
  }

  isLastPlaying() {
    if(this.player.song){
      return this.songList.lastIndexOf(this.player.song) === this.songList.length - 1;
    }
    return false;
  }

  playStream(url) {
    this.player.playStream(url).subscribe(events => {
    });
  }
  openFile(song) {
    this.storage.selectedSong.next(song);
    this.progressValue = 0;
    this.player.stop();
    // this.player.resetCountCheck.next(true);
    this.playStream(song.filename);
  }

  openSong(song) {
    this.player.song = song;
  }

  onRangeChange(event){
    const newVal = event.detail.value;
    if(this.progressValue != newVal){
      const seekToVal = newVal*this.state.duration;
      this.player.seekTo(seekToVal);
    }
  }

  velocityRangeChange(event){
    let newVal = event.detail.value;
    if(this.state.playbackRate != newVal){
      this.player.playbackRate(newVal);
      localStorage.setItem('playbackRate', newVal.toString());
    }
  }

  resetVelocity() {
    let newVal = 1;
    if(this.state.playbackRate != newVal){
      this.player.playbackRate(newVal);
      localStorage.setItem('playbackRate', newVal.toString());
    }
  }

  toggleShuffleMode() {
    this.isRepeatModeEnabled = false;
    this.isShuffleModeEnabled = !this.isShuffleModeEnabled;
    localStorage.setItem('repeatMode', this.isRepeatModeEnabled.toString());
    localStorage.setItem('shuffleMode', this.isShuffleModeEnabled.toString());
  }

  toggleRepeatMode() {
    this.isShuffleModeEnabled = false;
    this.isRepeatModeEnabled = !this.isRepeatModeEnabled;
    localStorage.setItem('shuffleMode', this.isShuffleModeEnabled.toString());
    localStorage.setItem('repeatMode', this.isRepeatModeEnabled.toString());
  }

  async loadPlaylists() {
    // this.playlists = [
    //   {name: 'Playlist', songList: [], id: 1},
    //   {name: 'Playlist 2', songList: [], id: 2}
    // ]
    this.playlists = await this.http.getPlaylists();
  }

  changeActionSelected(action) {
    this.actionSelected = action;
  }

  openPlaylistModal(action) {
    this.changeActionSelected(action);
    this.setOpen(true);
  }

  dismissModal() {
    this.actionSelected = null;
    this.setOpen(false);
  }

  async addSongToPlaylist(playlist: Playlist) {
    this.actionSelected = 'loader';
    if (playlist && this.player.song) {
      const added = await this.http.addSongToPlaylist(this.player.song, playlist);
      if (added) {
        this.actionSelected = 'addedSong';
        // this.startAutocloseModal('addedSong');
      }
    }
    return true;
  }

  async createPlaylist() {
    if (this.newPlaylist) {
      this.actionSelected = 'loader';
      const playlistCreated = await this.http.savePlaylist(this.newPlaylist);
      if (playlistCreated) {
        if (playlistCreated && this.player.song) {
          const added = await this.http.addSongToPlaylist(this.player.song, playlistCreated);
          if (added) {
            this.actionSelected = 'addedSong';
            this.newPlaylist = new Playlist();
            this.startAutocloseModal('addedSong');
          } else {
            this.actionSelected = 'error';
            this.startAutocloseModal('error');
          }
        }
      } else {
        this.actionSelected = 'error';
        this.startAutocloseModal('error');
      }
    }
  }

  setOpen(isOpen: boolean) {
    this.isModalOpen = isOpen;
  }

  startAutocloseModal(currentSelection: string) {
    const current = currentSelection;
    const timeout = setTimeout(() => {
      if (this.isModalOpen && current === this.actionSelected) {
        this.setOpen(false);
      }
    }, 2000)
  }
}
