import { Component, ContentChild, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';

@Component({
  selector: 'app-m-input',
  templateUrl: './m-input.component.html',
  styleUrls: ['./m-input.component.scss'],
})
export class MInputComponent implements OnInit {
  @Input() label: string;
  @Input() type: string;
  @Input() value: string;
  @Input() isDisabled: boolean;
  @Output() returnValue = new EventEmitter<string>();
  showPassword = false;
  @ViewChild('input', {static: true}) input: IonInput;

  constructor() { }

  ngOnInit() {}


  onChange(event: any){
    this.returnValue.emit(event.detail.value);
  }
  toggleShow() {
    this.showPassword = !this.showPassword;
    this.input.type = this.showPassword ? 'text' : 'password';
  }
}
