import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { LoginResponseModel } from 'src/app/models/login-responseModel';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  constructor(
    private storage: StorageService,
    private navCtrl: NavController
  ){

  }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user: LoginResponseModel = this.storage.getItem("user");
    if(user && user.data.user.subscription.status == "Active"){
      return true;
    } else {
      this.navCtrl.navigateBack("auth/login");
    }
  }
  
}
