import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-m-backbutton-text',
  templateUrl: './m-backbutton-text.component.html',
  styleUrls: ['./m-backbutton-text.component.scss'],
})
export class MBackbuttonTextComponent implements OnInit {
  @Input() title: string;
  @Input() hideButton: boolean;
  constructor(
    private navCtrl: NavController
  ) { }

  ngOnInit() {}

  goBack(){
    this.navCtrl.back();
  }
}
