import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { StreamState } from "src/app/models/streamState";
import { StorageService } from "../storage/storage.service";
import { HttpService } from "../http/http.service";
import { Song } from "src/app/models/songModel";

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  public song: Song;
  private lastCounted: Song;
  private stop$ = new Subject();
  public static audioObj = new Audio();
  songSeconds = 0;
  intervalId: any;
  private state: StreamState = {
    playing: false,
    readableCurrentTime: '',
    readableDuration: '',
    playbackRate: undefined,
    duration: undefined,
    // song: this.storage.getItem("current-song"),
    currentTime: undefined,
    canplay: false,
    error: false,
  };
  private stateChange: BehaviorSubject<StreamState> = new BehaviorSubject(
    this.state
  );
  public loadNextPage: BehaviorSubject<boolean> = new BehaviorSubject(true);
  // public resetCountCheck: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public songList: BehaviorSubject<Song[]> = new BehaviorSubject([]);
  constructor(
    private storage: StorageService,
    private http: HttpService,
  ) { 
  }

  audioEvents = [
    "ended",
    "error",
    "play",
    "playing",
    "pause",
    "timeupdate",
    "canplay",
    "loadedmetadata",
    "loadstart"
  ];

  private streamObservable(url): any {
    return new Observable(observer => {
      // Play audio
      PlayerService.audioObj.src = url;
      PlayerService.audioObj.load();
      // PlayerService.audioObj.autoplay = true;
      // PlayerService.audioObj.play();
  
      const handler = (event: Event) => {
        this.updateStateEvents(event);
        observer.next(event);
      };
  
      this.addEvents(PlayerService.audioObj, this.audioEvents, handler);
      return () => {
        // Stop Playing
        PlayerService.audioObj.pause();
        PlayerService.audioObj.currentTime = 0;
        // remove event listeners
        this.removeEvents(PlayerService.audioObj, this.audioEvents, handler);
        // reset state
        this.resetState();
      };
    });
  }

  private addEvents(obj, events, handler) {
    events.forEach(event => {
      obj.addEventListener(event, handler);
    });
  }

  private removeEvents(obj, events, handler) {
    events.forEach(event => {
      obj.removeEventListener(event, handler);
    });
  }

  playStream(url) {
    return this.streamObservable(url).pipe(takeUntil(this.stop$));
  }

  addSongCount() {
    this.http.addSongCount(this.song.id).subscribe((data) => {
    });
  }

  play() {
    if(PlayerService.audioObj.paused) {
      PlayerService.audioObj.play();
      this.intervalId = setInterval(() => {
        if (this.state.playing) {
          // console.log(this.state.readableCurrentTime);
          this.songSeconds++;
          if (this.songSeconds > 15 && (!this.lastCounted || this.lastCounted.id !== this.song.id)) {
            this.lastCounted = Object.assign({}, this.song);
            this.addSongCount();
          }
        }
      }, 1000);
    }
  }

  pause() {
    if(!PlayerService.audioObj.paused) {
      PlayerService.audioObj.pause();
      clearInterval(this.intervalId);
    }
  }

  stop() {
    this.stop$.next();
    this.songSeconds = 0;
    this.lastCounted = null;
    clearInterval(this.intervalId);
  }

  seekTo(seconds) {
    if(PlayerService.audioObj.currentTime){  
      PlayerService.audioObj.currentTime = seconds;
    }
  }

  playbackRate(rate) {
    if(PlayerService.audioObj.playbackRate){  
      PlayerService.audioObj.playbackRate = rate;
    }
  }

  getState(): Observable<StreamState> {
    return this.stateChange.asObservable();
  }

  formatTime(time: number, format: string = "mm:ss") {
    const momentTime = time * 1000;
    return moment.utc(momentTime).format(format);
  }

  private updateStateEvents(event: Event): void {
    switch (event.type) {
      case "canplay":
        this.state.duration = PlayerService.audioObj.duration;
        this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.playbackRate = PlayerService.audioObj.playbackRate;
        this.state.canplay = true;
        break;
      case "playing":
        this.state.playing = true;
        break;
      case "pause":
        this.state.playing = false;
        break;
      case "timeupdate":
        this.state.currentTime = PlayerService.audioObj.currentTime;
        this.state.readableCurrentTime = this.formatTime(
          this.state.currentTime
        );
        this.state.playbackRate = PlayerService.audioObj.playbackRate;
        break;
      case "error":
        this.resetState();
        this.state.error = true;
        break;
    }
    this.stateChange.next(this.state);
  }

  private resetState() {
    this.state = {
      playing: false,
      readableCurrentTime: '',
      readableDuration: '',
      playbackRate: undefined,
      duration: undefined,
      currentTime: undefined,
      // song:  this.storage.getItem("current-song"),
      canplay: false,
      error: false
    };
  }
}
