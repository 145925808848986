import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-subscription',
  templateUrl: './m-subscription.component.html',
  styleUrls: ['./m-subscription.component.scss'],
})
export class MSubscriptionComponent implements OnInit {
  @Input() selectedSubscription: number;
  @Input() title: string;
  @Input() subscription: any;
  @Input() price: string;
  @Input() pricetag: string;
  @Input() id: number;
  constructor() { }

  ngOnInit() {}

}
