import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionSheetController, AlertController, ModalController } from '@ionic/angular';
import { Dance } from 'src/app/models/danceModel';
import { Playlist } from 'src/app/models/playlist';
import { SeachHelper } from 'src/app/models/searchHelper';
import { Song, TrainingSessionSong } from 'src/app/models/songModel';
import { StreamState } from 'src/app/models/streamState';
import { TrainingSession } from 'src/app/models/trainingSession';
import { HttpService } from 'src/app/services/http/http.service';
import { PlayerService } from 'src/app/services/player/player.service';
import { TrainingPlayerService } from 'src/app/services/player/training-player.service';
import { StorageService } from 'src/app/services/storage/storage.service';


@Component({
  selector: 'app-training-player-modal',
  templateUrl: './training-player-modal.component.html',
  styleUrls: ['./training-player-modal.component.scss'],
})
export class TrainingPlayerModalComponent implements OnInit {
  state: StreamState;
  public static searchHelper: SeachHelper = {};
  progressValue: number;
  playbackValue: number = 1;
  @Input() songList: TrainingSessionSong[];
  @Input() trainingSession: TrainingSession;
  actionSelected = null;
  newPlaylist: Playlist = new Playlist();
  playlists: Playlist[] = [];
  isModalOpen = false;
  danceList: Dance[] = [{ name: 'Samba rumena' }, { name: 'danza' }, { name: 'prova danza' }, { name: 'prova danza' }, { name: 'prova danza' }, { name: 'prova danza' }];
  totalCurrentDuration = 0;
  totalStartingDuration = 0;
  previousDuration = 0;
  totalRemainingDuration: string = '00:00';
  maxDurationReadable: string;
  maxDuration: number;
  intervalId: any;
  dissolveIntervalId: any;
  counting = false;
  dissolving = false;
  trackHistory: TrainingSessionSong[] = [];
  currentTrackIndex = 0;
  volumeRate = 1;
  constructor(
    private modalController: ModalController,
    private storage: StorageService,
    private http: HttpService,
    private actionSheets: ActionSheetController,
    public trainingPlayer: TrainingPlayerService,
    public player: PlayerService
  ) {
    this.trainingPlayer = new TrainingPlayerService(http);
    setTimeout(() => {
      this.trainingPlayer.getState()
        .subscribe(state => {
          this.state = state;
          if (state?.currentTime > 0 && !this.counting) {
            this.startCountDown();
          }
          this.progressValue = (this.state.currentTime / this.state.duration);
          // this.setMaxDuration(this.state);
          // this.getProgressValue(this.state);
          if (this.state.playbackRate) {
            this.playbackValue = this.state.playbackRate;
          }
        });
      this.playbackValue = localStorage.getItem('trainingPlaybackRate') ? parseFloat(localStorage.getItem('trainingPlaybackRate')) : 1;
    }, 0);
  }

  ngOnInit() {
    this.player.stop();
    if (this.songList && this.songList.length > 0) {
      // console.log(this.songList);
      const startingSong = this.songList[0];
      this.loadSong(startingSong);
    }

    this.loadPlaylists();
  }

  async close() {
    const actionSheets = await this.actionSheets.create({
      header: 'Confermi di voler terminare l\'allenamento in corso?',
      buttons: [
        {
          text: 'Termina',
          role: 'confirm'
        },
        {
          text: 'Annulla',
          role: 'cancel'
        }
      ],
    });
    actionSheets.present();

    const { role } = await actionSheets.onWillDismiss();
    if (role && role === 'confirm') {
      this.stop();
      this.modalController.dismiss();
    }
  }

  play() {
    this.trainingPlayer.play();
    // this.startCountDown();
  }
  pause() {
    this.trainingPlayer.pause();
    this.stopCountDown();
  }

  stop() {
    this.trainingPlayer.stop();
    this.stopCountDown();
  }

  async nextRandom() {
    if (this.trainingPlayer.song && this.trainingPlayer.song.type === 'track') {
      this.trackHistory.push(this.trainingPlayer.song);
    }
    const randomSong = await this.http.getRandomTrainingTrack(this.trainingPlayer.song?.dance.id, this.trainingSession.track_duration);
    if (randomSong) {
      this.loadSong(randomSong);
      this.totalCurrentDuration = 0;
      this.previousDuration = 0;
      this.play();
    }

  }

  async next() {
    // const index = this.songList.lastIndexOf(this.trainingPlayer.song) + 1;
    this.currentTrackIndex++;
    this.goToCurrentDance(this.currentTrackIndex);
    const file = this.songList[this.currentTrackIndex];
    this.loadSong(file);
    this.trackHistory = [];
    this.totalCurrentDuration = 0;
    this.previousDuration = 0;
    this.play();
  }

  goToCurrentDance(elementId: number) {
    const dance = document.getElementById(elementId.toString());
    if (dance) {
      dance.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center'});
    }
  }

  async previousRandom() {
    if (this.trainingPlayer.song && this.trainingPlayer.song.type === 'track') {
      const previousRandomSong = this.trackHistory.pop();
      if (previousRandomSong) {
        this.loadSong(previousRandomSong);
        this.totalCurrentDuration = 0;
        this.previousDuration = 0;
        this.play();
      }
    }
  }

  async previous() {
    const index = this.songList.lastIndexOf(this.trainingPlayer.song) - 1;
    const file = this.songList[index];
    this.loadSong(file);
    this.play();
  }

  async loadSong(song: TrainingSessionSong) {
    this.trainingPlayer.song = song;
    this.openFile(song);
    this.trainingPlayer.playbackRate(this.playbackValue);
  }

  skipToDanceSong(song) {
    if (this.songList.indexOf(song) !== this.currentTrackIndex) {
      this.currentTrackIndex = this.songList.indexOf(song);
      this.loadSong(song);
      this.trackHistory = [];
    }
  }

  isFirstPlaying() {
    if (this.trainingPlayer.song) {
      return this.songList.lastIndexOf(this.trainingPlayer.song) === 0;
    }
    return false;
  }

  isLastPlaying() {
    if (this.trainingPlayer.song) {
      return this.songList.lastIndexOf(this.trainingPlayer.song) === this.songList.length - 1;
    }
    return false;
  }

  startCountDown() {
    if (!this.counting) {
      this.counting = true;
      // this.totalStartingDuration--;
      this.intervalId = setInterval(() => {
        if (this.state.playing) {
          this.totalStartingDuration--;
          this.setRemainingDuration();
        }
      }, 1000);
    }
  }

  stopCountDown() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.counting = false;
  }

  setRemainingDuration() {
    if (this.totalStartingDuration >= 0) {
      const minutes = Math.trunc(this.totalStartingDuration / 60);
      const seconds = this.totalStartingDuration - (minutes * 60);
      this.totalRemainingDuration = minutes.toString() + ':' + ('0' + seconds.toString()).slice(-2);
      if (this.totalStartingDuration < 6 && this.volumeRate > 0 && !this.dissolving) {
        this.dissolveVolume();
      }
    } else {
      if (!this.isLastPlaying()) {
        this.next();
      } else {
        this.stop();
      }
    }
  }

  // setMaxDuration(state) {
  //   const maxSongDuration = state.duration;
  //   if (maxSongDuration && maxSongDuration > 0) {
  //     const maxDuration = Math.trunc(maxSongDuration);
  //     if (this.trainingPlayer.song && this.trainingPlayer.song.type === 'track') {
  //       if (maxDuration <= this.trainingSession.track_duration) {
  //         this.maxDurationReadable = state.readableDuration;
  //         this.maxDuration = state.duration;
  //         return;
  //       } else {
  //         const minutes = Math.trunc(this.trainingSession.track_duration / 60);
  //         const seconds = this.trainingSession.track_duration - (minutes * 60);
  //         const trackDurationLimitFormatted = ('0'+ minutes.toString()).slice(-2) + ':' + ('0'+ seconds.toString()).slice(-2);
  //         this.maxDurationReadable = trackDurationLimitFormatted;
  //         this.maxDuration = this.trainingSession.track_duration;
  //         return;
  //       }
  //     } else if (this.trainingPlayer.song && this.trainingPlayer.song.type === 'pause') {
  //       const minutes = Math.trunc(this.trainingSession.pause_duration / 60);
  //       const seconds = this.trainingSession.pause_duration - (minutes * 60);
  //       const pauseDurationLimitFormatted = ('0'+ minutes.toString()).slice(-2) + ':' + ('0'+ seconds.toString()).slice(-2);
  //       this.maxDurationReadable = pauseDurationLimitFormatted;
  //       this.maxDuration = this.trainingSession.pause_duration;
  //       return;
  //     }
  //   }
  //   this.maxDurationReadable = state.readableDuration;
  //   this.maxDuration = state.duration;
  // }

  // getProgressValue(state) {
  //   if (this.trainingPlayer.song && this.trainingPlayer.song.type === 'track') {
  //     if (state.duration <= this.trainingSession.track_duration) {
  //       this.progressValue = (state.currentTime / state.duration);
  //       return;
  //     } else {
  //       this.progressValue = (state.currentTime / this.trainingSession.track_duration);
  //       return;
  //     }
  //   } else if (this.trainingPlayer.song && this.trainingPlayer.song.type === 'pause') {
  //     this.progressValue = (state.currentTime / this.trainingSession.pause_duration);
  //     return;
  //   }
  //   this.progressValue = (state.currentTime / state.duration);
  // }

  playStream(url) {
    this.trainingPlayer.playStream(url).subscribe(events => {
    });
  }
  openFile(song: TrainingSessionSong) {
    this.storage.selectedSong.next(song);
    this.progressValue = 0;
    if (song && song.type === 'track') {
      const minutes = Math.trunc(this.trainingSession.track_duration / 60);
      const seconds = this.trainingSession.track_duration - (minutes * 60);
      this.totalRemainingDuration = minutes.toString() + ':' + ('0' + seconds.toString()).slice(-2);
      this.totalStartingDuration = this.trainingSession.track_duration;
      this.volumeRate = 1;
      this.trainingPlayer.volumeRate(this.volumeRate);
    } else if (song && song.type === 'pause') {
      const minutes = Math.trunc(this.trainingSession.pause_duration / 60);
      const seconds = this.trainingSession.pause_duration - (minutes * 60);
      this.totalRemainingDuration = minutes.toString() + ':' + ('0' + seconds.toString()).slice(-2);
      this.totalStartingDuration = this.trainingSession.pause_duration;
      this.volumeRate = 1;
      this.trainingPlayer.volumeRate(this.volumeRate);
    } else {
      const minutes = Math.trunc(song.duration / 60);
      const seconds = song.duration - (minutes * 60);
      this.totalRemainingDuration = minutes.toString() + ':' + ('0' + seconds.toString()).slice(-2);
      this.totalStartingDuration = song.duration;
      this.volumeRate = 1;
      this.trainingPlayer.volumeRate(this.volumeRate);
    }
    this.stop();
    this.playStream(song.filename);
  }

  openSong(song) {
    this.trainingPlayer.song = song;
  }

  onRangeChange(event) {
    const newVal = event.detail.value;
    if (this.progressValue != newVal) {
      const seekToVal = newVal * this.state.duration;
      this.trainingPlayer.seekTo(seekToVal);
    }
    // const newVal = event.detail.value;
    // if (this.progressValue != newVal) {
    //   let seekToVal;
    //   if (this.trainingPlayer.song && this.trainingPlayer.song.type === 'track') {
    //     if (this.state.duration <= this.trainingSession.track_duration) {
    //       seekToVal = newVal * this.state.duration;
    //     } else {
    //       seekToVal = newVal * this.trainingSession.track_duration;
    //     }
    //   } else if (this.trainingPlayer.song && this.trainingPlayer.song.type === 'pause') {
    //     seekToVal = newVal * this.trainingSession.pause_duration;
    //   } else {
    //     seekToVal = newVal * this.state.duration;
    //   }
    //   this.trainingPlayer.seekTo(seekToVal);
    // }
  }

  velocityRangeChange(event) {
    let newVal = event.detail.value;
    if (this.state.playbackRate != newVal) {
      this.trainingPlayer.playbackRate(newVal);
      localStorage.setItem('trainingPlaybackRate', newVal.toString());
    }
  }

  resetVelocity() {
    let newVal = 1;
    if (this.state.playbackRate != newVal) {
      this.trainingPlayer.playbackRate(newVal);
      localStorage.setItem('trainingPlaybackRate', newVal.toString());
    }
  }

  dissolveVolume() {
    if (!this.dissolving) {
      this.dissolving = true;
      this.dissolveIntervalId = setInterval(() => {
        if (this.volumeRate > 0) {
          this.volumeRate = this.volumeRate - 0.02;
          if (this.volumeRate > 0.02) {
            this.trainingPlayer.volumeRate(this.volumeRate);
          } else {
            this.volumeRate = 0;
            this.trainingPlayer.volumeRate(this.volumeRate);
            clearInterval(this.dissolveIntervalId);
            this.dissolving = false;
          }
        } else {
          this.volumeRate = 0;
          this.trainingPlayer.volumeRate(this.volumeRate);
          clearInterval(this.dissolveIntervalId);
          this.dissolving = false;
        }
      }, 100);
    }
  }

  async loadPlaylists() {
    this.playlists = await this.http.getPlaylists();
  }

  changeActionSelected(action) {
    this.actionSelected = action;
  }

  openPlaylistModal(action) {
    this.changeActionSelected(action);
    this.setOpen(true);
  }

  dismissModal() {
    this.actionSelected = null;
    this.setOpen(false);
  }

  async addSongToPlaylist(playlist: Playlist) {
    this.actionSelected = 'loader';
    if (playlist && this.trainingPlayer.song) {
      const added = await this.http.addSongToPlaylist(this.trainingPlayer.song, playlist);
      if (added) {
        this.actionSelected = 'addedSong';
        // this.startAutocloseModal('addedSong');
      }
    }
    return true;
  }

  async createPlaylist() {
    if (this.newPlaylist) {
      this.actionSelected = 'loader';
      const playlistCreated = await this.http.savePlaylist(this.newPlaylist);
      if (playlistCreated) {
        if (playlistCreated && this.trainingPlayer.song) {
          const added = await this.http.addSongToPlaylist(this.trainingPlayer.song, playlistCreated);
          if (added) {
            this.actionSelected = 'addedSong';
            this.newPlaylist = new Playlist();
            this.startAutocloseModal('addedSong');
          } else {
            this.actionSelected = 'error';
            this.startAutocloseModal('error');
          }
        }
      } else {
        this.actionSelected = 'error';
        this.startAutocloseModal('error');
      }
    }
  }

  setOpen(isOpen: boolean) {
    this.isModalOpen = isOpen;
  }

  startAutocloseModal(currentSelection: string) {
    const current = currentSelection;
    const timeout = setTimeout(() => {
      if (this.isModalOpen && current === this.actionSelected) {
        this.setOpen(false);
      }
    }, 2000)
  }
}
