import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private alertController: AlertController, private navController: NavController) { }


  async showAlert(text: string, islogin?: boolean, title?: string) {
    if(islogin){
      const alert = await this.alertController.create({
        header: title ? title : 'Attenzione',
        message: text,
        buttons: [
          {
            text: "Login",
            handler: () => {
              // this.navController.navigateBack("/auth/login");
            }
          }
        ],
        mode: "ios"
      });
      await alert.present();
      return;
    }
    const alert = await this.alertController.create({
      header: title ? title : 'Attenzione',
      message: text,
      buttons: ['OK'],
      mode: "ios"
    });

    await alert.present();
  }


  async showAlertOptions(text: string) {
    const alert = await this.alertController.create({
      header: 'Attenzione',
      message: text,
      buttons: [
        {
          text: 'CANCEL',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'OK',
          handler: () => {
          }
        }
      ]
    });
  
    await alert.present();
  }
}
