import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { IosService } from 'src/app/services/ios/ios.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-m-external-link',
  templateUrl: './m-external-link.component.html',
  styleUrls: ['./m-external-link.component.scss'],
})


export class MExternalLinkComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  @Input() isModalOpen: boolean;
  isIpad = false;

  constructor(private iosService: IosService) { }

  ngOnInit() {
    this.isIpad = this.iosService.isIpad;
  }

  dismissModal() {
    this.isModalOpen = false;
  }

  openLearnMore() {
    window.open('https://apps.apple.com/story/id1614232807', "_blank");
  }

  goToExternalLink() {
    window.open(environment.app_url, "_blank");
  }

}
