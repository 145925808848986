import { Component, Input, OnInit } from '@angular/core';
import { DisciplineModel } from 'src/app/models/disciplineModel';

@Component({
  selector: 'app-m-card-dance',
  templateUrl: './m-card-dance.component.html',
  styleUrls: ['./m-card-dance.component.scss'],
})
export class MCardDanceComponent implements OnInit {
  @Input() discipline: DisciplineModel;
  constructor() { }

  ngOnInit() {}

}
