import { Component, Input, OnInit } from '@angular/core';
import { Song } from 'src/app/models/songModel';

@Component({
  selector: 'app-m-album-item',
  templateUrl: './m-album-item.component.html',
  styleUrls: ['./m-album-item.component.scss'],
})
export class MAlbumItemComponent implements OnInit {
  @Input() song: Song;
  
  constructor() { }

  ngOnInit() {}

}
