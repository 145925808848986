import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
@Component({
  selector: 'app-m-profile-header',
  templateUrl: './m-profile-header.component.html',
  styleUrls: ['./m-profile-header.component.scss'],
})
export class MProfileHeaderComponent implements OnInit {
  @Input() activeProfileIcon = false;
  @Input() activeHeadsetIcon = false;
  @Output() showSearch = new EventEmitter<boolean>();
  searchController: boolean;
  @Input() showSearchButton: boolean;
  @Input() showBackNormalHome: boolean;
  @ViewChild('popover') popover;
  isOpen = false;
  constructor(
    private navCtrl: NavController,
    private router: Router
  ) { }

  ngOnInit() {}

  goToProfile(){
    this.navCtrl.navigateRoot('profile');
  }

  goToHome(){
    this.navCtrl.navigateRoot('home');
  }

  goToPlaylist() {
    const id = this.router.url.split('/home/pid/')[1];
    // this.navCtrl.navigateBack('profile/edit-playlist');
    this.navCtrl.navigateForward('profile/edit-playlist/detail/' + id);
  }

  openSearch(){
    this.searchController = !this.searchController;
    this.showSearch.emit(this.searchController);
  }

  goToPage(link: string){
    this.navCtrl.navigateForward('profile/' + link);
  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }
}
