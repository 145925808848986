import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gradient-background',
  templateUrl: './gradient-background.component.html',
  styleUrls: ['./gradient-background.component.scss'],
})
export class GradientBackgroundComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
