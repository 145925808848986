import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { LoaderService } from '../loader/loader.service';
import { LoadingController, NavController } from '@ionic/angular';
import { AlertService } from '../alert/alert.service';
import { PlayerService } from '../player/player.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    loaderToShow: any;
    constructor(
        private storage: StorageService,
        // private loader: LoaderService,
        public loadingController: LoadingController,
        private alert: AlertService,
        private player: PlayerService,
        private NavCtrl: NavController
    ) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // this.loader.presentLoading();
        const token = this.storage.getItem("token");

        if (token) {
            request = request.clone({
                setHeaders: {
                    'Authorization': 'Bearer '+ token
                }
            });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({
                setHeaders: {
                    'content-type': 'application/json'
                }
            });
        }

        request = request.clone({
            headers: request.headers.set('Accept', 'application/json')
        });

        // this.showLoader();
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                // this.hideLoader();
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                // this.hideLoader();
                if(error.status == 401){
                    localStorage.clear();
                    this.player.stop();
                    this.NavCtrl.navigateBack("auth/login");
                    this.alert.showAlert("La sessione è scaduta, oppure hai effettuato l'accesso con un altro dispositivo.",true);
                }
                return throwError(error);
            }));
    }




    showLoader() {
        this.loaderToShow = this.loadingController.create({
            cssClass: 'loader',
            message: 'Please wait...',
        }).then((res) => {
            res.present();

            res.onDidDismiss().then((dis) => {
                // console.log('Loading dismissed!');
            });
        });
        this.hideLoader();
    }

    hideLoader() {
        this.loadingController.dismiss();
    }

}