import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { SeachHelper } from 'src/app/models/searchHelper';
import { Song, SongModel } from 'src/app/models/songModel';
import { StreamState } from 'src/app/models/streamState';
import { HttpService } from 'src/app/services/http/http.service';
import { PlayerService } from 'src/app/services/player/player.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { FilterModalComponent } from '../filter-modal/filter-modal.component';
import { PlayerModalComponent } from '../player-modal/player-modal.component';

@Component({
  selector: 'app-m-sticky-player',
  templateUrl: './m-sticky-player.component.html',
  styleUrls: ['./m-sticky-player.component.scss'],
})
export class MStickyPlayerComponent implements OnInit {
  state: StreamState;
  progressValue: number;
  @Input() songList: Song[];
  song: Song;
  public static searchHelper: SeachHelper = {};
  isChangingSong: boolean;
  @Input() selectedSong: Song;
  @Input() playIt: boolean;
  hasSentCount: boolean;
  playbackValue: number;
  modal: any;
  constructor(
    public player: PlayerService,
    private modalController: ModalController,
    private http: HttpService,
    private storage: StorageService,
    private router: Router
  ) {
    // this.player.resetCountCheck.subscribe( (reset: boolean) => {
    //   this.hasSentCount = false;
    // });
    // this.playbackValue = localStorage.getItem('playbackRate') ? parseFloat(localStorage.getItem('playbackRate')) : 1;
    // PlayerService.audioObj.playbackRate = this.playbackValue;
    this.player.getState()
      .subscribe(state => {
        this.state = state;
        this.progressValue = (this.state.currentTime / this.state.duration);
        // if (this.player.song && this.state.currentTime > 15) {
        //   if (this.hasSentCount === false) {
        //     this.hasSentCount = true;
        //     this.http.addSongCount(this.player.song.id).subscribe((data) => {
        //     });
        //   }
        // }
      });
    this.playbackValue = localStorage.getItem('playbackRate') ? parseFloat(localStorage.getItem('playbackRate')) : 1;
    this.player.songList.subscribe((data) => {
      this.songList = data;
    });
    setTimeout(() => {
      if (this.selectedSong) {
        this.player.song = this.selectedSong;
        // this.songList = [this.player.song].concat(this.songList);
      } else {
        this.player.song = this.songList[0];
      }
      // for (let index = 0; index < this.songList.length; index++) {
      //   this.songList[index].index = index;
      // }
      this.openFile(this.player.song);
      if (this.playIt) {
        this.play();
      }
      this.storage.selectedSong.subscribe((data) => {
        this.selectedSong = data;
      });
      PlayerService.audioObj.onended = () => {
        this.playbackValue = this.state.playbackRate;
        this.next();
      }
    }, 0);
    // this.getAlbumSongs();
  }

  ngOnInit() {
  }
  play() {
    this.player.play();
  }
  pause() {
    this.player.pause();
  }
  async next() {
    const playback = this.playbackValue;
    const isRepeatModeEnabled = localStorage.getItem('repeatMode');
    const isShuffleModeEnabled = localStorage.getItem('shuffleMode');
    let index;
    if (isRepeatModeEnabled && isRepeatModeEnabled === 'true') {
      index =this.songList.lastIndexOf(this.player.song)
    } else if (isShuffleModeEnabled && isShuffleModeEnabled === 'true') {
      const song = await this.getRandomTrack();
      this.openFile(song);
  
      this.play();
    } else {
      index =this.songList.lastIndexOf(this.player.song) + 1;
    }
    if (!isShuffleModeEnabled || isShuffleModeEnabled !== 'true') {
      if (index == this.songList.length - 2) {
        this.player.loadNextPage.next(true);
      } else if (index === -1) {
        const file = this.songList[0];
        this.openFile(file);
        this.play();
      } else if (index === this.songList.length) {
        const file = this.songList[0];
        this.openFile(file);
      } else {
        const file = this.songList[index];
        this.openFile(file);
    
        this.play();
      }
    }
  }
  isLastPlaying() {
    if (this.player.song) {
      return this.songList.lastIndexOf(this.player.song) === this.songList.length - 1;
    }
  }
  onSliderChangeEnd(change) {
    this.player.seekTo(change.value);
  }
  playStream(url) {
    this.player.playStream(url).subscribe(events => {
      this.isChangingSong = false;
    });
  }
  openFile(song) {
    const playback = this.playbackValue;
    this.progressValue = 0;
    this.player.song = song;
    this.player.stop();
    this.hasSentCount = false;
    this.playStream(song.filename);
    if (playback) {
      this.player.playbackRate(playback);
    }
  }

  onRangeChange(event) {
    const newVal = event.detail.value;
    if (this.progressValue != newVal) {
      const seekToVal = newVal * this.state.duration;
      this.player.seekTo(seekToVal);
    }
  }

  async openModal() {
    this.modal = await this.modalController.create({
      component: PlayerModalComponent,
      componentProps: { player: this.player, song: this.player.song, songList: this.songList },
      cssClass: 'player-modal',
      showBackdrop: true
    });
    this.modal.onDidDismiss()
      .then((data) => {
        this.player.song = data.data.selectedSong;
        this.playbackValue = data.data.playback;
        this.modal = undefined;
      });
    return await this.modal.present();
  }

  // getAlbumSongs() {
  //   const hepler: SeachHelper = {
  //     disciplines: [this.state.song.discipline.id]
  //   }
  //   this.http.getSongs(hepler).subscribe((data: any) => {
  //     this.songList = data;
  //     for (let index = 0; index < this.songList.length; index++) {
  //       this.songList[index].index = index;
  //     }
  //     this.player.song = this.songList[0];
  //     this.openFile(this.player.song);
  //   })
  // }

  trimSongName(name: string) {
    return name.length > 20 ? name.slice(0, 17) + "..." : name;
  }

  getRandomTrack() {
    MStickyPlayerComponent.searchHelper.disciplines = [this.songList[0].discipline.id];
    MStickyPlayerComponent.searchHelper.dances = [this.songList[0].dance.id];
    let tags = null;
    try {
      tags = this.storage.getItem('tags');
    } catch (e) {
      tags = null;
    }
    if (tags && tags !== undefined && tags.length > 0) {
      MStickyPlayerComponent.searchHelper.tags = tags;
    }
    if (this.songList[0].playlist_id) {
      MStickyPlayerComponent.searchHelper.playlist = this.songList[0].playlist_id;
    }
    if (this.player.song) {
      PlayerModalComponent.searchHelper.currentSong = this.player.song.id;
    }
    return this.http.getRandomTrack(MStickyPlayerComponent.searchHelper);
  }
}
