import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MButtonOutlineComponent } from './m-button-outline/m-button-outline.component';
import { MInputComponent } from './m-input/m-input.component';
import { FormsModule } from '@angular/forms';
import { MInputIconComponent } from './m-input-icon/m-input-icon.component';
import { ResponsiveContainerComponent } from './responsive-container/responsive-container.component';
import { GradientBackgroundComponent } from './gradient-background/gradient-background.component';
import { MSubscriptionComponent } from './m-subscription/m-subscription.component';
import { GridCenterComponent } from './grid-center/grid-center.component';
import { MTermsAndConditionsComponent } from './m-terms-and-conditions/m-terms-and-conditions.component';
import { MProfileHeaderComponent } from './m-profile-header/m-profile-header.component';
import { MBackbuttonTextComponent } from './m-backbutton-text/m-backbutton-text.component';
import { MButtonGradientComponent } from './m-button-gradient/m-button-gradient.component';
import { MButtonDanceComponent } from './m-button-dance/m-button-dance.component';
import { MAlbumItemComponent } from './m-album-item/m-album-item.component';
import { MCardDanceComponent } from './m-card-dance/m-card-dance.component';
import { MStickyPlayerComponent } from './m-sticky-player/m-sticky-player.component';
import { MExternalLinkComponent } from './m-external-link/m-external-link.component';

@NgModule({
    declarations: [
        MButtonOutlineComponent,
        MInputComponent,
        MInputIconComponent,
        ResponsiveContainerComponent,
        GradientBackgroundComponent,
        MSubscriptionComponent,
        GridCenterComponent,
        MTermsAndConditionsComponent,
        MProfileHeaderComponent,
        MBackbuttonTextComponent,
        MButtonGradientComponent,
        MButtonDanceComponent,
        MAlbumItemComponent,
        MCardDanceComponent,
        MStickyPlayerComponent,
        MExternalLinkComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule
    ],
    exports: [
        MButtonOutlineComponent, 
        MInputComponent, 
        MInputIconComponent, 
        ResponsiveContainerComponent, 
        GradientBackgroundComponent,        
        MSubscriptionComponent,
        GridCenterComponent,
        MTermsAndConditionsComponent,
        MProfileHeaderComponent,
        MBackbuttonTextComponent,
        MButtonDanceComponent,
        MButtonGradientComponent,
        MAlbumItemComponent,
        MCardDanceComponent,
        MStickyPlayerComponent,
        MExternalLinkComponent
    ],
})
export class SharedComponentModule { }