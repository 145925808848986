import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './services/interceptors/httpConfig.interceptor';
import { PlayerModalComponent } from './components/player-modal/player-modal.component';
import { SharedComponentModule } from './components/shared.module';
import { FormsModule } from '@angular/forms';
import { TrainingPlayerModalComponent } from './components/training-player-modal/training-player-modal.component';
// import { ApplePay } from '@ionic-native/apple-pay/ngx';

@NgModule({
  declarations: [AppComponent, PlayerModalComponent, TrainingPlayerModalComponent],
  entryComponents: [],
  imports: [BrowserModule,
    IonicModule.forRoot({
      backButtonText: '',
      mode: 'ios'
    }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedComponentModule,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    // ApplePay
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
