import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Song, SongModel } from 'src/app/models/songModel';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  showSticker: BehaviorSubject<boolean> = new BehaviorSubject(true);
  songs: BehaviorSubject<Song[]> = new BehaviorSubject([]);
  selectedSong: BehaviorSubject<Song> = new BehaviorSubject(undefined);
  constructor() { }

  setItem(name: string, data: any){
    localStorage.setItem(name, JSON.stringify(data));
  }

  getItem(name: string){
    return JSON.parse(localStorage.getItem(name));
  }

  removeItem(name: string){
    localStorage.removeItem(name);
  }


  clearAll(){
    localStorage.clear();
  }
}
